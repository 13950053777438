// Timer
class Timer {
  constructor() {
    this.startTime = 0;
    this.currentTime = 0;
    this.intervalId = null;
  }

  start() {
    this.startTime = Date.now();
    this.intervalId = setInterval(() => {
      this.currentTime = (Date.now() - this.startTime) / 1000;
      let formattedTime;
      if (this.currentTime >= 60) {
        const minutes = Math.floor(this.currentTime / 60);
        const seconds = (this.currentTime % 60).toFixed(1).padStart(4, "0");
        formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds}`;
      } else {
        formattedTime = this.currentTime.toFixed(1).padStart(4, "0");
      }
      document.getElementById("timer-display").innerText = formattedTime;
    }, 100);
  }

  stop() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    return this.currentTime;
  }
}

// Modal
class Modal {
  constructor(modalId, resetGame = undefined) {
    this.modal = document.getElementById(modalId);
    this.closeButton = this.modal.querySelector(".close-button");
    this.messageElement = this.modal.querySelector("p") || this.modal.querySelector("pre");
    // close
    this.resetGame = resetGame;
    this.closeButton.addEventListener("click", () => this.close());
    window.addEventListener("click", (event) => {
      if (event.target === this.modal) {
        this.close();
      }
    });
  }

  open(message) {
    if (this.messageElement.tagName === "pre") {
      this.messageElement.innerHTML = message;
    } else {
      this.messageElement.innerText = message;
    }

    this.modal.style.display = "block";
  }

  close() {
    this.modal.style.display = "none";
    this.messageElement.innerText = "";
    if (this.resetGame) {
      this.resetGame();
    }
  }
}

// document ready
document.addEventListener("DOMContentLoaded", () => {
  let timer = new Timer();
  let countdownIntervalId = null;
  let maskTimerId = null;
  let targetTime = 0;
  let playerName = "";
  let maxLength = 5;

  const playerNameInput = document.getElementById("player-name");
  const startButton = document.getElementById("start-button");
  const stopButton = document.getElementById("stop-button");
  const itsNowButton = document.getElementById("its-now-button");
  const statsButton = document.getElementById("stats-button");
  const difficultySelect = document.getElementById("difficulty");
  const timerDisplay = document.getElementById("timer-display");
  const targetTimeDisplay = document.getElementById("target-time-display");

  const statsModal = new Modal("stats-modal");
  const resultModal = new Modal("result-modal", resetGame);

  // init reset game
  resetGame();

  // Set Target Time
  function setTargetTime(difficulty) {
    switch (difficulty) {
      // ref: https://klab.tw/2022/12/solve-the-problem-of-math-random-and-axios-set-attribute-after-weak-scan/#%E8%A7%A3%E6%B1%BA
      case "easy":
        targetTime = parseFloat("0." + crypto.getRandomValues(new Uint32Array(1))[0]) * 7 + 8; // 8~15s
        // targetTime = Math.random() * 7 + 8;
        break;
      case "medium":
        targetTime = parseFloat("0." + crypto.getRandomValues(new Uint32Array(1))[0]) * 15 + 20; // 20~35s
        // targetTime = Math.random() * 15 + 20;
        break;
      case "hard":
        targetTime = parseFloat("0." + crypto.getRandomValues(new Uint32Array(1))[0]) * 60 + 60; // 1m ~ 2m
        // targetTime = Math.random() * 60 + 60;
        break;
    }

    if (targetTimeDisplay) {
      let formattedTime;
      if (targetTime >= 60) {
        const minutes = Math.floor(targetTime / 60);
        const seconds = (targetTime % 60).toFixed(1).padStart(4, "0");
        formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds}`;
        targetTimeDisplay.innerText = `目標時間： ${formattedTime}s (${
          minutes > 0 ? `${minutes}分` : ""
        }${seconds}秒)`;
      } else {
        formattedTime = targetTime.toFixed(1).padStart(4, "0");
        targetTimeDisplay.innerText = `目標時間： ${formattedTime}s (秒)`;
      }
    }
  }

  // XSS: sanitizeOutput
  function sanitizeOutput(str) {
    return str
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  // addEventListeners-Start
  playerNameInput.addEventListener("change", () => {
    playerName = sanitizeOutput(playerNameInput.value.trim()); // sanitizeOutput player name
  });

  playerNameInput.addEventListener("keydown", (event) => {
    // Blur on Enter key press
    if (event.key === "Enter") {
      playerNameInput.blur();
    }
  });

  startButton.addEventListener("click", () => startGame());

  stopButton.addEventListener("click", () => stopGame());

  itsNowButton.addEventListener("click", () => stopGame());

  statsButton.addEventListener("click", () => showStats());

  difficultySelect.addEventListener("change", () => setTargetTime(difficultySelect.value));
  // addEventListeners-End

  function startGame() {
    // block no name
    if (!playerName) {
      resultModal.open("錯誤：請輸入玩家名稱！");
      return;
    }

    // block playing or countdown
    if (timer.intervalId || countdownIntervalId) {
      return;
    }

    triggerButtonDisabledStats(true);

    // 開始前倒數 3... 2... 1
    let countdown = 3;
    timerDisplay.classList.add("countdown");
    timerDisplay.innerText = countdown;

    // closure
    function gameStart() {
      if (countdown < 0) {
        countdownIntervalId && clearInterval(countdownIntervalId);
        countdownIntervalId = null;
        timerDisplay.classList.remove("countdown");
        timerDisplay.innerText = "00.0";
        // start timer
        timer.start();
        // add Mask
        maskTimerId = setTimeout(() => {
          timerDisplay.classList.add("masked");
        }, 3000);
      }
    }

    countdownIntervalId = setInterval(() => {
      countdown -= 1;

      if (countdown > 0) {
        timerDisplay.innerText = countdown;
      } else {
        // game real Start
        gameStart();
      }
    }, 1000);
  }

  function stopGame() {
    // block "not" playing or countdown
    if (!timer.intervalId || countdownIntervalId) {
      return;
    }

    // count
    const finalTime = timer.stop();
    const result = (finalTime - targetTime).toFixed(1); // 0.1+0.2=0.300000000000004 fixed
    saveResult(playerName, result);
    resultModal.open(`你的結果：${result > 0 ? "+" : ""}${result == 0 ? "±00.0" : result} 秒`);
    // clearTimeout
    maskTimerId && clearTimeout(maskTimerId);
    maskTimerId = null;
    // remove Mask
    setTimeout(() => {
      timerDisplay.classList.remove("masked");
    }, 100);
  }

  function triggerButtonDisabledStats(started) {
    startButton.disabled = started;
    stopButton.disabled = !started;
    itsNowButton.disabled = !started;
    statsButton.disabled = started;
  }

  function resetGame() {
    if (countdownIntervalId) {
      return;
    }

    triggerButtonDisabledStats(false);
    timer = new Timer();
    targetTimeDisplay.innerText = "";
    timerDisplay.innerText = "00.0";
    // init target Timer
    setTargetTime(difficultySelect.value);
  }

  function saveResult(playerName, result) {
    const stats = JSON.parse(localStorage.getItem(playerName)) || {
      easy: [],
      medium: [],
      hard: [],
    };
    const difficulty = difficultySelect.value;
    stats[difficulty].unshift(parseFloat(result));
    stats[difficulty].length =
      stats[difficulty].length >= maxLength ? maxLength : stats[difficulty].length;
    localStorage.setItem(playerName, JSON.stringify(stats));
  }

  function showStats() {
    // block no name
    if (!playerName) {
      resultModal.open("錯誤：請輸入玩家名稱！");
      return;
    }

    // block playing or countdown
    if (timer.intervalId || countdownIntervalId) {
      return;
    }

    const stats = JSON.parse(localStorage.getItem(playerName)) || {
      easy: [],
      medium: [],
      hard: [],
    };

    let message = `玩家名稱：${playerName}\n`;

    for (const level in stats) {
      const times = stats[level];
      if (times.length) {
        const total = times.reduce((acc, cur) => acc + cur, 0);
        const average = (total / times.length).toFixed(1);
        const best =
          times.length >= 2
            ? times.reduce((prev, curr) => {
                return Math.abs(curr) < Math.abs(prev) ? curr : prev;
              })
            : times[0];
        message += `\n${level.toUpperCase()} - 平均時間：${average} 秒,\n　最佳時間：${best} 秒,\n　最近結果：${times
          .slice(-maxLength)
          .join(", ")}`;
      } else {
        message += `\n${level.toUpperCase()} - 沒有紀錄`;
      }
    }

    statsModal.open(message);
  }

  // HotKeys
  document.addEventListener("keydown", function (event) {
    if (event.code === "Space") {
      stopGame();
    } else if (event.shiftKey && event.code === "Enter") {
      startGame();
    } else if (event.shiftKey && event.code === "Slash") {
      showStats();
    } else if (event.shiftKey && event.key === "S") {
      stopGame();
    } else if (event.code === "Escape") {
      resultModal.close();
      statsModal.close();
    }
  });
});
